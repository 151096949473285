

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Couleurs                                                                          */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

$color_body: #ccd6e0;
$color_body-degrade: #eaeef2;
$color_column: #3b3238;

$color_logo_bkg: #1f141e;
$color_logo: rgba(255, 255, 255, 0.5);
$color_logo_2: #ff0099;

$color_navbar: rgba(52, 61, 61, 0.13);
$color_navbar2: rgba(52, 61, 61, 0.08);


$color_toolbox: #00e7ff;
$color_toolbox_dark: #009fac;

$color_default : #c4cece;
$color_primary : #0062bf;
$color_success : #56ad00;
$color_info : #00a1ad;
$color_warning : #fba900;
$color_danger : #d8002c;

$color_link: #005b97;
$color_text: #111;

$color_pink : #fb00e2;
$color_purple : #6200ff;
$color_horizon : #51697b;
$color_sable : #76501c;



/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Dimensions                                                                        */
/* ///////////////////////////////////////////////////////////////////////////////////////*/


$header-height: 55px;
$aside-width: 250px;


$font_text : 'Apercu Pro';
$font_title : 'Apercu Pro';

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);

