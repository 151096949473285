@use "sass:map";
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Styles de texte                                                                   */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

h1,h2,h3,h4,h5,h6 {
    font-family: $font_title;
    font-weight: 600;
    margin-bottom: 0.75rem;
    line-height: 0.8;
    color: #000;
}
h1 {
    font-size: 2.2rem;
    small {
        font-size: 1.3rem;
    }
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.75rem;
    font-weight: 400;
}
h4 {
    font-size: 1.5rem;
    font-weight: 400;
}
h5 {
    font-size: 1.25rem;
    font-weight: bold;
}
h6 {
    font-size: 1rem;
    font-weight: 500;
}

blockquote{
    position: relative;
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFF;
    @media (max-width: map.get($grid-breakpoints, "xl")) {font-size: 1.4rem;}
    @media (max-width: map.get($grid-breakpoints, "lg")) {font-size: 1.2rem;}
}

.text-light {color:#FFF !important;}
.text-dark {color:#FFF !important;}
.text-left {text-align: left !important;}
.text-center {text-align: center !important;}
.text-right {text-align: right !important;}

/* ------------------------------------------------------------*/

.text-default {color: $color_default !important;}
.text-primary {color: $color_primary !important;}
.text-success {color: $color_success !important;}
.text-info {color: $color_info !important;}
.text-warning {color: $color_warning !important;}
.text-danger, .text-red {color: $color_danger !important;}
.text-toolbox {color: $color_toolbox !important;}
.text-link {color: $color_link !important;}
.text-pink {color: $color_pink !important;}
.text-purple {color: $color_purple !important;}
.text-horizon {color: $color_horizon !important;}
.text-sable {color: $color_sable !important;}
.text-light {color: #FFF !important;}
.text-dark {color: #000 !important;}

hr{
    height: 1px;
    margin: 1rem 0;
    padding: 0;
    color: rgba(0,0,0,0.2);
    background-color: rgba(0,0,0,0.2);
    border: 0;
}

.text-md {font-size: 0.9rem;}
.text-sm {font-size: 0.8rem;}
.text-xs {font-size: 0.7rem;}

img.fluid {
    width: 100%;
    margin-bottom: 1.5rem;
}

/*--------------------------------  Badge  -----------------------------------------  */

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .35rem;
    &.badge-light{color:#FFF;}
    &.badge-outline{
        border: solid 1px #000;
    }
}
.badge-default{ background-color:   $color_default  !important; color: #FFF; }
.badge-primary{ background-color:   $color_primary  !important; color: #FFF;  }
.badge-success{ background-color:   $color_success  !important; color: #FFF;  }
.badge-info{ background-color:      $color_info     !important; color: #FFF;  }
.badge-warning{ background-color:   $color_warning  !important; color: #FFF;  }
.badge-danger{ background-color:    $color_danger   !important; color: #FFF;  }
.badge-horizon{ background-color:   $color_horizon  !important; color: #FFF; }
.badge-sable{ background-color:     $color_sable    !important; color: #FFF;  }

.badge-outline.badge-default{ background-color: #FFF !important; color: $color_default  !important; border-color: $color_default  !important; }
.badge-outline.badge-primary{ background-color: #FFF !important; color: $color_primary  !important; border-color: $color_primary  !important;  }
.badge-outline.badge-success{ background-color: #FFF !important; color: $color_success  !important; border-color: $color_success  !important;  }
.badge-outline.badge-info   { background-color: #FFF !important; color: $color_info     !important; border-color: $color_info     !important;  }
.badge-outline.badge-warning{ background-color: #FFF !important; color: $color_warning  !important; border-color: $color_warning  !important;  }
.badge-outline.badge-danger { background-color: #FFF !important; color: $color_danger   !important; border-color: $color_danger   !important;  }
.badge-outline.badge-horizon{ background-color: #FFF !important; color: $color_horizon  !important; border-color: $color_horizon  !important; }
.badge-outline.badge-sable  { background-color: #FFF !important; color: $color_sable    !important; border-color: $color_sable    !important;  }

