@use "sass:map";
/*////////////////////////////////////////////////////////////////*/
/* background */

body {background-image: linear-gradient(90deg, $color_body-degrade , $color_body 33%);}

.image-preload {
    position: absolute;
    top: -9999px;
    width: 1px;
    height: 1px;
}
.background-anim {
    position: fixed;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    z-index: -1;
    pointer-events: none;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .carousel-img-top{
        opacity: 0;
        z-index: 2;
        transition: opacity 800ms ease-out;
        &.show {opacity: 1;}
    }
}

/*////////////////////////////////////////////////////////////////*/
/* header */
.page-header{
    &.scroll{ background-color: rgba(255,255,255,0.8);}
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: transparent;
    transition: background-color 300ms ease-out;
    padding-bottom: 1rem;
    .header-container{
        @media (max-width: map.get($grid-breakpoints, "xl")) {
            width: 950px;
        }
        @media (max-width: map.get($grid-breakpoints, "lg")) {
            width: 700px;
            padding-top: 10px;
            height: 60px;
        }
        width:1180px;
        margin: 0 auto;
        padding-top: 30px;
        display: flex;
        #logo{
            @media (max-width: map.get($grid-breakpoints, "xl")) {
                flex-basis: 350px;
            }
            @media (max-width: map.get($grid-breakpoints, "lg")) {
                position: absolute;
                width: 250px;
                left: 40px;
            }
            flex-basis: 500px;
            flex-grow: 0;
            .logo{
                @media (max-width: map.get($grid-breakpoints, "lg")) {
                    padding-top: 50px;
                }
                display:block;
                overflow: hidden;
                height:0;
                padding-top: 75px;
                width:100%;
                background: transparent url('../img/IDN_LOGO.svg') no-repeat;
                background-size: contain;
            }
        }
        #main-nav {
            .hamburger{
                display: none;
                position: absolute;
                width: 20px;
                border: solid 2px #000;
                border-width: 3px 0;
                overflow : hidden;
                padding-top: 13px;
                height: 0;
                right: 20px;
                top: 16px;
                &:before{
                    position: absolute;
                    content: ' ';
                    border-top: solid 3px #000;
                    height: 1px;
                    width: 100%;
                    top: 5px;
                }
                @media (max-width: map.get($grid-breakpoints, "lg")) {
                    display: block;
                }
            }
            flex-grow: 1;
            ul {
                @media (max-width: map.get($grid-breakpoints, "lg")) {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top : -500px;
                    padding: 0;
                    background-color: #FFF;
                    flex-direction: column;
                    transition: top ease-out 300ms;
                }
                display: flex;
                padding: 10px 0 0 0;
                li {
                    list-style: none;
                    flex-grow: 1;
                    text-align: right;
                    a {
                        @media (max-width: map.get($grid-breakpoints, "lg")) {
                            display: block;
                            text-align: center;
                            padding: 0.75rem 0;
                            border-bottom: solid 1px #DDD;
                        }
                        font-family: $font_text;
                        color: #000;
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 0.9rem;
                        &.sel{
                            font-weight: 900;
                        }
                    }
                }
            }
        }
    }
}

.sous-nav {
    @media (max-width: map.get($grid-breakpoints, "lg")) {
        ul {
            flex-direction: column;
            li{
                padding-left: 0 !important;
                &:before {border: none  !important;}
            }
        }
    }
    margin-bottom: 1rem;
    ul {
        padding:0;
        margin:0;
        display: flex;
        justify-content: center;
        li:first-child{
            padding-left: 0;
           &:before { border: none;}
        }
        li {
            position: relative;
            list-style: none;
            padding: 0 0.8rem;
            height: 25px;
            &:before{
                position: absolute;
                left: 0;
                bottom: 25%;
                display: block;
                content: ' ';
                border-left: 3px solid #FFF;
                width: 1px;
                height: 50%;
            }
            a {
                position:relative;
                display: block;
                color: #000;
                font-weight: bold;
                font-size: 1.15rem;
                text-transform: uppercase;
                /*
                overflow: hidden;
                height: 19px;
                transition: height 500ms ease-out;*/
                &.sel, &:hover{
                    /*height: 100%;*/
                }
                &:after {
                    display : block;
                    position:absolute;
                    width: 100%;
                    height: 3px;
                    content: ' ';
                    background-color: #ccd6e0 ;
                    bottom:6px;
                    transition: height 300ms ease-out;
                }
                &.sel:after, &:hover:after{
                    height: 0;
                }
            }
        }
    }
}

.site-container {
    padding-top: 100px;
}

/*////////////////////////////////////////////////////////////////*/
/* content */
ul {
    padding: 0;
    li {list-style: none;}
}
ul.list-date {
    li{
        b {font-weight: bold;}
        margin-bottom: 1rem;
        a {
            color: #000;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.spectacle ul.list-date {
    padding: 10px;
    border: solid 4px #FFF;
    li {
        margin-bottom: 0.5rem;
    }

}
ul.media-link {
    li a{
        position: relative;
        display: block;
        color: #000;
        font-weight: bold;
        padding-top: 8px;
        padding-left: 35px;
        margin-bottom: 8px;
        &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            content: ' ';
            width: 30px;
            height: 0;
            padding-top : 100%;
            overflow: hidden;
            background: transparent url('../img/PICTO AUDIO.svg') no-repeat;
            background-size: contain;
        }
    }
}
ul.actu {
    li {
        display: flex;
        div {
            flex-basis: 30%;
            margin-right: 20px;
        }
    }
}
.site-container{
    width:1180px;
    margin: 0 auto;
    @media (max-width: map.get($grid-breakpoints, "xl")) {
        width: 950px;
    }
    @media (max-width: map.get($grid-breakpoints, "lg")) {
        width: 700px;
    }
    @media (max-width: map.get($grid-breakpoints, "md")) {
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
.content-center {
    width:1180px;
    margin: 0 auto;
}

.text-deco-home {
    position: absolute;
    font-family: $font_text;
    top: 5rem;
    color: #FFF;
    font-size: 3.2rem;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: bold;
    transition: top 600ms ease-out;
    z-index: 3;
    &.slow {
        transition: top 5000ms ease-out;
    }
    small {
        font-size: 0.7em;
    }
    @media (max-width: map.get($grid-breakpoints, "xl")) {
        padding-left:1rem;
        font-size: 2.8rem;
    }
    @media (max-width: map.get($grid-breakpoints, "md")) {
        font-size: 2rem;
    }
}

.img-fluid img{
    width: 100%;
    margin-bottom: 1.5rem;
}

.page-wrapper {
    padding-top: 4rem;
}
.error {color: red;}
.content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 5rem;
    font-weight: 300;
    line-height: 1.4;
    h4 {
        font-weight: bold;
        font-size: 1.4rem;
    }
    h5 {
        font-size: 1.2rem;
    }
    a {text-decoration: underline;}
    .download-file {
        position: relative;
        display: block;
        color: #000;
        font-weight: bold;
        padding-top: 8px;
        padding-left: 35px;
        margin-bottom: 8px;
        &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            content: ' ';
            width: 30px;
            height: 0;
            padding-top : 100%;
            overflow: hidden;
            background: transparent url('../img/PICTO TELECHARGEMENT DOSSIER.svg') no-repeat;
            background-size: contain;
        }
    }
}

.past {
    opacity: 0.5;
}
/*////////////////////////////////////////////////////////////////*/
/* footer */

.page-footer{
    position: fixed;
    bottom:0;
    left:0;
    right:0;
}
.page-footer{
    width: 100%;
    background-color: #FFF;
    padding: 0.3rem 0 1.25rem 0;
    .footer-container{
        width:1180px;
        @media (max-width: map.get($grid-breakpoints, "xl")) {width: 100%;}
        margin: 0 auto;
        p {
            margin: 0;
            text-align: right;
            font-size: 0.8rem;
            @media (max-width: map.get($grid-breakpoints, "xl")) {padding-right: 2rem;}
        }
    }
}

/*////////////////////////////////////////////////////////////////*/
/* picto */

.picto {
    display: inline-block;
    width: 22px;
    height: 18px;
    background-position-x: 50%;
    background-position-y: 100%;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
}
.picto-facebook {
    height: 18px;
    width: 18px;
    background-image: url('../img/picto-facebook.svg');
}
.picto-instagram {
    height: 18px;
    width: 18px;
    background-image: url('../img/picto-instagram.svg');
}
.picto-twitter {
    background-image: url('../img/picto-twitter.svg');
}
.picto-youtube {
    background-image: url('../img/picto-youtube.svg');
}

/*////////////////////////////////////////////////////////////////*/
/* carousel */

.carousel {
    position: relative;
    width: 100%;
    height: 500px;
    @media (max-width: map.get($grid-breakpoints, "xl")) {height: 400px;}
    @media (max-width: map.get($grid-breakpoints, "lg")) {height: 350px;}
    @media (max-width: map.get($grid-breakpoints, "md")) {height: 320px;}
    @media (max-width: map.get($grid-breakpoints, "sm")) {height: 220px;}
    overflow: hidden;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .carousel-img-top {
        opacity: 0;
        transition: opacity 600ms ease-out;
        &.show {opacity: 1;}
    }
    &.carousel-cropped {
        img{
            width: 91.77777%;
            @media (max-width: map.get($grid-breakpoints, "lg")) {width: 87%;}
            @media (max-width: map.get($grid-breakpoints, "md")) {width: 100%;}
        }

    }
    &:hover .carousel-text.on{opacity: 0;transition: opacity 300ms ease-out;}
    .carousel-text {
        position: absolute;
        display: flex;
        right: -60%;
        z-index: 2;
        width: 60%;
        height: 100%;
        color: #FFF;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.6rem;
        line-height: 1.2;
        opacity: 0;
        transition: left 2000ms ease-out, right 2000ms ease-out, opacity 2000ms ease-out;
        &.on {
            right: 0;
            opacity: 1;
        }
        .txt {
            align-self: center;
        }
        @media (max-width: map.get($grid-breakpoints, "xl")) {font-size: 1.3rem;}
        @media (max-width: map.get($grid-breakpoints, "lg")) {font-size: 1.1rem; width: 50%;}
        @media (max-width: map.get($grid-breakpoints, "md")) {display: none;}
    }
    &.carousel-spectacle{
        .carousel-text {
            right: auto;
            left: 100%;
            &.on {
                left: 0;
            }
        }
        img{
            width: 91%;
            right: 0;
        }
    }
}


/*////////////////////////////////////////////////////////////////*/
/* accordeon */

.accordeon-item{
    overflow: hidden;
    height: 35px;
    transition: height 300ms ease-out;
    @media (max-width: map.get($grid-breakpoints, "md")) {
        height: auto;
    }
    .accordeon-header{
        position: relative;
        display: block;
        color: #000;
        background-color: #FFF;
        padding: 0.2rem;
        margin-bottom: 0.5rem;
        font-weight: bold;
        cursor: pointer;
        &:after {
            position: absolute;
            right: 0.5rem;
            top: 0.25rem;
            display: block;
            content: ' ';
            width: 20px;
            height: 20px;
            background: transparent url('../img/CROIX.svg') no-repeat;
            background-size: contain;
            transition: transform 500ms ease-out;
        }
        &.open:after {
            transform: rotate(45deg);
        }
    }
    .accordeon-content {

    }
}

/*////////////////////////////////////////////////////////////////*/
/* form */

.form-input-text{
    position: relative;
    border-bottom: solid 2px #FFF;
    margin-bottom: 1.2rem;
    input[type='text']{
        background-color: transparent;
        width: 100%;
        outline : none;
        border: none;
    }
    label {
        left: 0;
        top: 0;
        position: absolute;
        transition: top ease-out 200ms, font-size ease-out 200ms;
    }
}
input:focus + label, .done label {
    top: -16px;
    font-size: 0.7rem;
}
textarea {
    border: none;
    width: 100%;
}
button {
    border: none;
    background-color: #FFF;
    padding: 0.3rem 1rem;
    font-weight: 500;
    &:focus {outline: none;}
}

/*////////////////////////////////////////////////////////////////*/
/* mozaic */

.mozaic {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom : 5rem;
    .mozaic-empty {
        display: block;
        flex-basis: 370px;
        @media (max-width: map.get($grid-breakpoints, "xl")) {
            flex-basis: 300px;
        }
        margin-bottom: 1rem;
    }
    .mozaic-tile {
        &.collapse {display: none;}
        display: block;
        flex-basis: 370px;
        @media (max-width: map.get($grid-breakpoints, "xl")) {
            flex-basis: 300px;
        }
        @media (max-width: map.get($grid-breakpoints, "lg")) {
            flex-basis: 47%;
        }
        @media (max-width: map.get($grid-breakpoints, "md")) {
            flex-basis: 100%;
        }
        margin-bottom: 1rem;
        cursor: pointer;
        .mozaic-content {
            position: relative;
            display: block;
            width: 100%;
            height: 330px;
            @media (max-width: map.get($grid-breakpoints, "xl")) {
                height: 280px;
            }
            @media (max-width: map.get($grid-breakpoints, "lg")) {
                height: 260px;
            }
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
            overflow: hidden;
        }
        .mozaic-text {
            position: absolute;
            display: block;
            text-align: left;
            padding: 1rem;
            color : #000;
            background-color: #FFF;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 1.3rem;
            @media (max-width: map.get($grid-breakpoints, "xl")) {
                font-size: 1.1rem;
            }
            @media (max-width: map.get($grid-breakpoints, "lg")) {
                display:none;
            }
            width: 100%;
            height: 100%;
            bottom: -100%;
            transition: bottom ease-out 300ms;
        }
        &:hover .mozaic-text{ bottom : 0;}
        .mozaic-legend {
            color : #000;
            display: block;
            text-align: center;
            font-weight: bold;
        }
        .mozaic-description {
            display: block;
            padding-top: 1rem;
            h6{margin-bottom: 0.75rem;}
            b {font-weight: 800;}
            p {
                font-weight: 500;
                font-size: 1rem;
                margin-bottom: 0.75rem;
            }
        }
    }
    .mozaic-categorie {
        &.collapse {display: none;}
        display: block;
        flex-basis: 370px;
        @media (max-width: map.get($grid-breakpoints, "xl")) {
            flex-basis: 300px;
        }
        @media (max-width: map.get($grid-breakpoints, "lg")) {
            flex-basis: 47%;
        }
        @media (max-width: map.get($grid-breakpoints, "md")) {
            flex-basis: 100%;
        }
        margin-bottom: 1rem;

        .mozaic-content {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 330px;
            background-color: #7d8995;
            @media (max-width: map.get($grid-breakpoints, "xl")) {
                height: 280px;
            }
            @media (max-width: map.get($grid-breakpoints, "lg")) {
                height: 260px;
            }
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
            text-transform : uppercase;
            font-size: 0.9em;
            letter-spacing: 4px;
            color: #FFF;
            p {margin: 0;}
            &:after {
                position: absolute;
                display: block;
                content: ' ';
                right: -19px;
                top: 0;
                bottom: 0;
                width: 20px;
                background: transparent url('../img/mozaic-categorie.png') 0 50% no-repeat;

            }
        }
    }
}

/*////////////////////////////////////////////////////////////////*/
/* spectacle */
.spectacle-intro {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.1;
    h1 {
        line-height: 1;
        font-size: 1.8rem;
       small {
            font-size: 0.9rem;
        }
    }
    p {margin: 0;}
    ul {
        padding: 0;
        margin-bottom: 0.5rem;
        li{
            list-style: none;
            font-weight: 600;
        }
    }
}

iframe {
    margin-bottom: 1rem;
}

