*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

@-ms-viewport {
  width: device-width;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  min-height: 100%;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: theme-color("primary");
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role=button],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Couleurs                                                                          */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Dimensions                                                                        */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.float-right {
  float: right;
}

a {
  color: #005b97;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-lg {
  display: none;
}
@media (max-width: 992px) {
  .d-lg {
    display: block;
  }
}

.d-md {
  display: none;
}
@media (max-width: 768px) {
  .d-md {
    display: block;
  }
}

.d-sm {
  display: none;
}
@media (max-width: 576px) {
  .d-sm {
    display: block;
  }
}

@media (max-width: 992px) {
  .d-min-lg {
    display: none;
  }
}

@media (max-width: 768px) {
  .d-min-md {
    display: none;
  }
}

@media (max-width: 576px) {
  .d-min-sm {
    display: none;
  }
}

.h-0 {
  position: absolute;
  top: -9999px;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

text-left {
  text-align: left;
}

text-center {
  text-align: center;
}

text-right {
  text-align: right;
}

.list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
}

dl.key-value {
  display: flex;
  flex-wrap: wrap;
  font-family: "Apercu Pro";
  border-top: solid 2px rgba(0, 0, 0, 0.2);
  border-bottom: solid 2px rgba(0, 0, 0, 0.2);
}
dl.key-value dt, dl.key-value dd {
  padding: 0.2em 0 0.1em 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  flex: 0 0 50%;
}
dl.key-value dt {
  font-weight: 600;
}
dl.key-value dt:last-of-type {
  border-bottom-width: 0;
}
dl.key-value dd {
  font-weight: 500;
  text-align: right;
}
dl.key-value dd:last-child {
  border-bottom-width: 0;
}

.font-size-09 {
  font-size: 0.9em;
}

.clear {
  clear: both;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*     Couleur et deco                                                                    */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.bg-default {
  background-color: #c4cece !important;
  color: #FFF;
}

.bg-primary {
  background-color: #0062bf !important;
  color: #FFF;
}

.bg-success {
  background-color: #56ad00 !important;
  color: #FFF;
}

.bg-info {
  background-color: #00a1ad !important;
  color: #FFF;
}

.bg-warning {
  background-color: #fba900 !important;
  color: #FFF;
}

.bg-danger {
  background-color: #d8002c !important;
  color: #FFF;
}

.bg-horizon {
  background-color: #51697b !important;
  color: #FFF;
}

.bg-sable {
  background-color: #76501c !important;
  color: #FFF;
}

.bg-horizon-light {
  background-color: #93a8b8 !important;
  color: #FFF;
}

.soft-gradient {
  background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
}

.stripped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  background-size: 16px 16px;
}

.soft-gradient.stripped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent), linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
  background-size: 16px 16px, cover;
}

.ecosse {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%), linear-gradient(-45deg, rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%);
  background-size: 45px 45px, 45px 45px;
}

.soft-gradient.ecosse {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%), linear-gradient(-45deg, rgba(0, 0, 0, 0.1) 2%, transparent 2%, transparent 20%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0.1) 35%, transparent 35%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 52%, transparent 52%, transparent 70%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0.1) 85%, transparent 85%), linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
  background-size: 45px 45px, 45px 45px, cover;
}

.shadow-light {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.shadow-medium {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3), 0 3px 6px rgba(0, 0, 0, 0.5) !important;
}

.shadow-strong {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5), 0 3px 6px rgba(0, 0, 0, 0.8) !important;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Styles de texte                                                                   */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
h1, h2, h3, h4, h5, h6 {
  font-family: "Apercu Pro";
  font-weight: 600;
  margin-bottom: 0.75rem;
  line-height: 0.8;
  color: #000;
}

h1 {
  font-size: 2.2rem;
}
h1 small {
  font-size: 1.3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
  font-weight: 400;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

h5 {
  font-size: 1.25rem;
  font-weight: bold;
}

h6 {
  font-size: 1rem;
  font-weight: 500;
}

blockquote {
  position: relative;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 600;
  text-transform: uppercase;
  color: #FFF;
}
@media (max-width: 1200px) {
  blockquote {
    font-size: 1.4rem;
  }
}
@media (max-width: 992px) {
  blockquote {
    font-size: 1.2rem;
  }
}

.text-light {
  color: #FFF !important;
}

.text-dark {
  color: #FFF !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

/* ------------------------------------------------------------*/
.text-default {
  color: #c4cece !important;
}

.text-primary {
  color: #0062bf !important;
}

.text-success {
  color: #56ad00 !important;
}

.text-info {
  color: #00a1ad !important;
}

.text-warning {
  color: #fba900 !important;
}

.text-danger, .text-red {
  color: #d8002c !important;
}

.text-toolbox {
  color: #00e7ff !important;
}

.text-link {
  color: #005b97 !important;
}

.text-pink {
  color: #fb00e2 !important;
}

.text-purple {
  color: #6200ff !important;
}

.text-horizon {
  color: #51697b !important;
}

.text-sable {
  color: #76501c !important;
}

.text-light {
  color: #FFF !important;
}

.text-dark {
  color: #000 !important;
}

hr {
  height: 1px;
  margin: 1rem 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
  border: 0;
}

.text-md {
  font-size: 0.9rem;
}

.text-sm {
  font-size: 0.8rem;
}

.text-xs {
  font-size: 0.7rem;
}

img.fluid {
  width: 100%;
  margin-bottom: 1.5rem;
}

/*--------------------------------  Badge  -----------------------------------------  */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
}
.badge.badge-light {
  color: #FFF;
}
.badge.badge-outline {
  border: solid 1px #000;
}

.badge-default {
  background-color: #c4cece !important;
  color: #FFF;
}

.badge-primary {
  background-color: #0062bf !important;
  color: #FFF;
}

.badge-success {
  background-color: #56ad00 !important;
  color: #FFF;
}

.badge-info {
  background-color: #00a1ad !important;
  color: #FFF;
}

.badge-warning {
  background-color: #fba900 !important;
  color: #FFF;
}

.badge-danger {
  background-color: #d8002c !important;
  color: #FFF;
}

.badge-horizon {
  background-color: #51697b !important;
  color: #FFF;
}

.badge-sable {
  background-color: #76501c !important;
  color: #FFF;
}

.badge-outline.badge-default {
  background-color: #FFF !important;
  color: #c4cece !important;
  border-color: #c4cece !important;
}

.badge-outline.badge-primary {
  background-color: #FFF !important;
  color: #0062bf !important;
  border-color: #0062bf !important;
}

.badge-outline.badge-success {
  background-color: #FFF !important;
  color: #56ad00 !important;
  border-color: #56ad00 !important;
}

.badge-outline.badge-info {
  background-color: #FFF !important;
  color: #00a1ad !important;
  border-color: #00a1ad !important;
}

.badge-outline.badge-warning {
  background-color: #FFF !important;
  color: #fba900 !important;
  border-color: #fba900 !important;
}

.badge-outline.badge-danger {
  background-color: #FFF !important;
  color: #d8002c !important;
  border-color: #d8002c !important;
}

.badge-outline.badge-horizon {
  background-color: #FFF !important;
  color: #51697b !important;
  border-color: #51697b !important;
}

.badge-outline.badge-sable {
  background-color: #FFF !important;
  color: #76501c !important;
  border-color: #76501c !important;
}

html {
  line-height: 1.15;
  min-height: 100%;
}

* {
  font-family: "Apercu Pro";
}

body {
  font-family: "Apercu Pro";
  background-color: #ccd6e0;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  min-height: 100%;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Grid                                                                              */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.grid {
  display: grid;
  grid-template-rows: 55px 1fr;
  grid-template-columns: 250px 1fr;
  height: 100%;
  min-height: 100vh;
}
.grid > aside {
  grid-row-start: 1;
  grid-row-end: 3;
}
.grid > header {
  position: relative;
  grid-column-start: 2;
  grid-column-end: span 2;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Layout                                                                            */
/* ///////////////////////////////////////////////////////////////////////////////////////*/
.wrapper {
  position: relative;
  min-height: 100%;
}
.wrapper > .content {
  padding: 1rem 0.5rem 1rem 1.75rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
}
.row.row-gutter-double > div:first-child {
  padding-right: 15px;
  padding-left: 7.5px;
}
.row.row-gutter-double > div:last-child {
  padding-right: 7.5px;
  padding-left: 15px;
}
.row.row-gutter-double > div {
  padding-right: 15px;
  padding-left: 15px;
}
.row.row-gutter-double.row-gutter-line > div:last-child {
  border-right-width: 0;
}
.row.row-gutter-double.row-gutter-line > div {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.row > div {
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
}
.row .col-1, .row .col-md-1, .row .col-sm-1, .row .col-xs-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
@media (max-width: 576px) {
  .row .col-1, .row .col-md-1, .row .col-sm-1, .row .col-xs-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-1, .row .col-md-1, .row .col-sm-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-1, .row .col-sm-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-1 {
  margin-left: 8.3333333333%;
}
@media (max-width: 768px) {
  .row .col-margin-left-1 {
    margin-left: 0;
  }
}
.row .col-margin-right-1 {
  margin-left: 8.3333333333%;
}
@media (max-width: 768px) {
  .row .col-margin-right-1 {
    margin-right: 0;
  }
}
.row .col-2, .row .col-md-2, .row .col-sm-2, .row .col-xs-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
@media (max-width: 576px) {
  .row .col-2, .row .col-md-2, .row .col-sm-2, .row .col-xs-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-2, .row .col-md-2, .row .col-sm-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-2, .row .col-sm-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-2 {
  margin-left: 16.6666666667%;
}
@media (max-width: 768px) {
  .row .col-margin-left-2 {
    margin-left: 0;
  }
}
.row .col-margin-right-2 {
  margin-left: 16.6666666667%;
}
@media (max-width: 768px) {
  .row .col-margin-right-2 {
    margin-right: 0;
  }
}
.row .col-3, .row .col-md-3, .row .col-sm-3, .row .col-xs-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
@media (max-width: 576px) {
  .row .col-3, .row .col-md-3, .row .col-sm-3, .row .col-xs-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-3, .row .col-md-3, .row .col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-3, .row .col-sm-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-3 {
  margin-left: 25%;
}
@media (max-width: 768px) {
  .row .col-margin-left-3 {
    margin-left: 0;
  }
}
.row .col-margin-right-3 {
  margin-left: 25%;
}
@media (max-width: 768px) {
  .row .col-margin-right-3 {
    margin-right: 0;
  }
}
.row .col-4, .row .col-md-4, .row .col-sm-4, .row .col-xs-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
@media (max-width: 576px) {
  .row .col-4, .row .col-md-4, .row .col-sm-4, .row .col-xs-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-4, .row .col-md-4, .row .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-4, .row .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-4 {
  margin-left: 33.3333333333%;
}
@media (max-width: 768px) {
  .row .col-margin-left-4 {
    margin-left: 0;
  }
}
.row .col-margin-right-4 {
  margin-left: 33.3333333333%;
}
@media (max-width: 768px) {
  .row .col-margin-right-4 {
    margin-right: 0;
  }
}
.row .col-5, .row .col-md-5, .row .col-sm-5, .row .col-xs-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
@media (max-width: 576px) {
  .row .col-5, .row .col-md-5, .row .col-sm-5, .row .col-xs-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-5, .row .col-md-5, .row .col-sm-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-5, .row .col-sm-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-5 {
  margin-left: 41.6666666667%;
}
@media (max-width: 768px) {
  .row .col-margin-left-5 {
    margin-left: 0;
  }
}
.row .col-margin-right-5 {
  margin-left: 41.6666666667%;
}
@media (max-width: 768px) {
  .row .col-margin-right-5 {
    margin-right: 0;
  }
}
.row .col-6, .row .col-md-6, .row .col-sm-6, .row .col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
@media (max-width: 576px) {
  .row .col-6, .row .col-md-6, .row .col-sm-6, .row .col-xs-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-6, .row .col-md-6, .row .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-6, .row .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-6 {
  margin-left: 50%;
}
@media (max-width: 768px) {
  .row .col-margin-left-6 {
    margin-left: 0;
  }
}
.row .col-margin-right-6 {
  margin-left: 50%;
}
@media (max-width: 768px) {
  .row .col-margin-right-6 {
    margin-right: 0;
  }
}
.row .col-7, .row .col-md-7, .row .col-sm-7, .row .col-xs-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
@media (max-width: 576px) {
  .row .col-7, .row .col-md-7, .row .col-sm-7, .row .col-xs-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-7, .row .col-md-7, .row .col-sm-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-7, .row .col-sm-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-7 {
  margin-left: 58.3333333333%;
}
@media (max-width: 768px) {
  .row .col-margin-left-7 {
    margin-left: 0;
  }
}
.row .col-margin-right-7 {
  margin-left: 58.3333333333%;
}
@media (max-width: 768px) {
  .row .col-margin-right-7 {
    margin-right: 0;
  }
}
.row .col-8, .row .col-md-8, .row .col-sm-8, .row .col-xs-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
@media (max-width: 576px) {
  .row .col-8, .row .col-md-8, .row .col-sm-8, .row .col-xs-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-8, .row .col-md-8, .row .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-8, .row .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-8 {
  margin-left: 66.6666666667%;
}
@media (max-width: 768px) {
  .row .col-margin-left-8 {
    margin-left: 0;
  }
}
.row .col-margin-right-8 {
  margin-left: 66.6666666667%;
}
@media (max-width: 768px) {
  .row .col-margin-right-8 {
    margin-right: 0;
  }
}
.row .col-9, .row .col-md-9, .row .col-sm-9, .row .col-xs-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
@media (max-width: 576px) {
  .row .col-9, .row .col-md-9, .row .col-sm-9, .row .col-xs-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-9, .row .col-md-9, .row .col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-9, .row .col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-9 {
  margin-left: 75%;
}
@media (max-width: 768px) {
  .row .col-margin-left-9 {
    margin-left: 0;
  }
}
.row .col-margin-right-9 {
  margin-left: 75%;
}
@media (max-width: 768px) {
  .row .col-margin-right-9 {
    margin-right: 0;
  }
}
.row .col-10, .row .col-md-10, .row .col-sm-10, .row .col-xs-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
@media (max-width: 576px) {
  .row .col-10, .row .col-md-10, .row .col-sm-10, .row .col-xs-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-10, .row .col-md-10, .row .col-sm-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-10, .row .col-sm-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-10 {
  margin-left: 83.3333333333%;
}
@media (max-width: 768px) {
  .row .col-margin-left-10 {
    margin-left: 0;
  }
}
.row .col-margin-right-10 {
  margin-left: 83.3333333333%;
}
@media (max-width: 768px) {
  .row .col-margin-right-10 {
    margin-right: 0;
  }
}
.row .col-11, .row .col-md-11, .row .col-sm-11, .row .col-xs-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
@media (max-width: 576px) {
  .row .col-11, .row .col-md-11, .row .col-sm-11, .row .col-xs-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-11, .row .col-md-11, .row .col-sm-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-11, .row .col-sm-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-11 {
  margin-left: 91.6666666667%;
}
@media (max-width: 768px) {
  .row .col-margin-left-11 {
    margin-left: 0;
  }
}
.row .col-margin-right-11 {
  margin-left: 91.6666666667%;
}
@media (max-width: 768px) {
  .row .col-margin-right-11 {
    margin-right: 0;
  }
}
.row .col-12, .row .col-md-12, .row .col-sm-12, .row .col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (max-width: 576px) {
  .row .col-12, .row .col-md-12, .row .col-sm-12, .row .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .row .col-12, .row .col-md-12, .row .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .row .col-12, .row .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.row .col-margin-left-12 {
  margin-left: 100%;
}
@media (max-width: 768px) {
  .row .col-margin-left-12 {
    margin-left: 0;
  }
}
.row .col-margin-right-12 {
  margin-left: 100%;
}
@media (max-width: 768px) {
  .row .col-margin-right-12 {
    margin-right: 0;
  }
}

/*////////////////////////////////////////////////////////////////*/
/* background */
body {
  background-image: linear-gradient(90deg, #eaeef2, #ccd6e0 33%);
}

.image-preload {
  position: absolute;
  top: -9999px;
  width: 1px;
  height: 1px;
}

.background-anim {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
}
.background-anim img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background-anim .carousel-img-top {
  opacity: 0;
  z-index: 2;
  transition: opacity 800ms ease-out;
}
.background-anim .carousel-img-top.show {
  opacity: 1;
}

/*////////////////////////////////////////////////////////////////*/
/* header */
.page-header {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  transition: background-color 300ms ease-out;
  padding-bottom: 1rem;
}
.page-header.scroll {
  background-color: rgba(255, 255, 255, 0.8);
}
.page-header .header-container {
  width: 1180px;
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
}
@media (max-width: 1200px) {
  .page-header .header-container {
    width: 950px;
  }
}
@media (max-width: 992px) {
  .page-header .header-container {
    width: 700px;
    padding-top: 10px;
    height: 60px;
  }
}
.page-header .header-container #logo {
  flex-basis: 500px;
  flex-grow: 0;
}
@media (max-width: 1200px) {
  .page-header .header-container #logo {
    flex-basis: 350px;
  }
}
@media (max-width: 992px) {
  .page-header .header-container #logo {
    position: absolute;
    width: 250px;
    left: 40px;
  }
}
.page-header .header-container #logo .logo {
  display: block;
  overflow: hidden;
  height: 0;
  padding-top: 75px;
  width: 100%;
  background: transparent url("../img/IDN_LOGO.svg") no-repeat;
  background-size: contain;
}
@media (max-width: 992px) {
  .page-header .header-container #logo .logo {
    padding-top: 50px;
  }
}
.page-header .header-container #main-nav {
  flex-grow: 1;
}
.page-header .header-container #main-nav .hamburger {
  display: none;
  position: absolute;
  width: 20px;
  border: solid 2px #000;
  border-width: 3px 0;
  overflow: hidden;
  padding-top: 13px;
  height: 0;
  right: 20px;
  top: 16px;
}
.page-header .header-container #main-nav .hamburger:before {
  position: absolute;
  content: " ";
  border-top: solid 3px #000;
  height: 1px;
  width: 100%;
  top: 5px;
}
@media (max-width: 992px) {
  .page-header .header-container #main-nav .hamburger {
    display: block;
  }
}
.page-header .header-container #main-nav ul {
  display: flex;
  padding: 10px 0 0 0;
}
@media (max-width: 992px) {
  .page-header .header-container #main-nav ul {
    position: absolute;
    width: 100%;
    left: 0;
    top: -500px;
    padding: 0;
    background-color: #FFF;
    flex-direction: column;
    transition: top ease-out 300ms;
  }
}
.page-header .header-container #main-nav ul li {
  list-style: none;
  flex-grow: 1;
  text-align: right;
}
.page-header .header-container #main-nav ul li a {
  font-family: "Apercu Pro";
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.9rem;
}
@media (max-width: 992px) {
  .page-header .header-container #main-nav ul li a {
    display: block;
    text-align: center;
    padding: 0.75rem 0;
    border-bottom: solid 1px #DDD;
  }
}
.page-header .header-container #main-nav ul li a.sel {
  font-weight: 900;
}

.sous-nav {
  margin-bottom: 1rem;
}
@media (max-width: 992px) {
  .sous-nav ul {
    flex-direction: column;
  }
  .sous-nav ul li {
    padding-left: 0 !important;
  }
  .sous-nav ul li:before {
    border: none !important;
  }
}
.sous-nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.sous-nav ul li:first-child {
  padding-left: 0;
}
.sous-nav ul li:first-child:before {
  border: none;
}
.sous-nav ul li {
  position: relative;
  list-style: none;
  padding: 0 0.8rem;
  height: 25px;
}
.sous-nav ul li:before {
  position: absolute;
  left: 0;
  bottom: 25%;
  display: block;
  content: " ";
  border-left: 3px solid #FFF;
  width: 1px;
  height: 50%;
}
.sous-nav ul li a {
  position: relative;
  display: block;
  color: #000;
  font-weight: bold;
  font-size: 1.15rem;
  text-transform: uppercase;
  /*
  overflow: hidden;
  height: 19px;
  transition: height 500ms ease-out;*/
}
.sous-nav ul li a.sel, .sous-nav ul li a:hover {
  /*height: 100%;*/
}
.sous-nav ul li a:after {
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  content: " ";
  background-color: #ccd6e0;
  bottom: 6px;
  transition: height 300ms ease-out;
}
.sous-nav ul li a.sel:after, .sous-nav ul li a:hover:after {
  height: 0;
}

.site-container {
  padding-top: 100px;
}

/*////////////////////////////////////////////////////////////////*/
/* content */
ul {
  padding: 0;
}
ul li {
  list-style: none;
}

ul.list-date li {
  margin-bottom: 1rem;
}
ul.list-date li b {
  font-weight: bold;
}
ul.list-date li a {
  color: #000;
}
ul.list-date li a:hover {
  text-decoration: underline;
}

.spectacle ul.list-date {
  padding: 10px;
  border: solid 4px #FFF;
}
.spectacle ul.list-date li {
  margin-bottom: 0.5rem;
}

ul.media-link li a {
  position: relative;
  display: block;
  color: #000;
  font-weight: bold;
  padding-top: 8px;
  padding-left: 35px;
  margin-bottom: 8px;
}
ul.media-link li a:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  width: 30px;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  background: transparent url("../img/PICTO AUDIO.svg") no-repeat;
  background-size: contain;
}

ul.actu li {
  display: flex;
}
ul.actu li div {
  flex-basis: 30%;
  margin-right: 20px;
}

.site-container {
  width: 1180px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .site-container {
    width: 950px;
  }
}
@media (max-width: 992px) {
  .site-container {
    width: 700px;
  }
}
@media (max-width: 768px) {
  .site-container {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.content-center {
  width: 1180px;
  margin: 0 auto;
}

.text-deco-home {
  position: absolute;
  font-family: "Apercu Pro";
  top: 5rem;
  color: #FFF;
  font-size: 3.2rem;
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: bold;
  transition: top 600ms ease-out;
  z-index: 3;
}
.text-deco-home.slow {
  transition: top 5000ms ease-out;
}
.text-deco-home small {
  font-size: 0.7em;
}
@media (max-width: 1200px) {
  .text-deco-home {
    padding-left: 1rem;
    font-size: 2.8rem;
  }
}
@media (max-width: 768px) {
  .text-deco-home {
    font-size: 2rem;
  }
}

.img-fluid img {
  width: 100%;
  margin-bottom: 1.5rem;
}

.page-wrapper {
  padding-top: 4rem;
}

.error {
  color: red;
}

.content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 5rem;
  font-weight: 300;
  line-height: 1.4;
}
.content h4 {
  font-weight: bold;
  font-size: 1.4rem;
}
.content h5 {
  font-size: 1.2rem;
}
.content a {
  text-decoration: underline;
}
.content .download-file {
  position: relative;
  display: block;
  color: #000;
  font-weight: bold;
  padding-top: 8px;
  padding-left: 35px;
  margin-bottom: 8px;
}
.content .download-file:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  width: 30px;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  background: transparent url("../img/PICTO TELECHARGEMENT DOSSIER.svg") no-repeat;
  background-size: contain;
}

.past {
  opacity: 0.5;
}

/*////////////////////////////////////////////////////////////////*/
/* footer */
.page-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.page-footer {
  width: 100%;
  background-color: #FFF;
  padding: 0.3rem 0 1.25rem 0;
}
.page-footer .footer-container {
  width: 1180px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .page-footer .footer-container {
    width: 100%;
  }
}
.page-footer .footer-container p {
  margin: 0;
  text-align: right;
  font-size: 0.8rem;
}
@media (max-width: 1200px) {
  .page-footer .footer-container p {
    padding-right: 2rem;
  }
}

/*////////////////////////////////////////////////////////////////*/
/* picto */
.picto {
  display: inline-block;
  width: 22px;
  height: 18px;
  background-position-x: 50%;
  background-position-y: 100%;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
}

.picto-facebook {
  height: 18px;
  width: 18px;
  background-image: url("../img/picto-facebook.svg");
}

.picto-instagram {
  height: 18px;
  width: 18px;
  background-image: url("../img/picto-instagram.svg");
}

.picto-twitter {
  background-image: url("../img/picto-twitter.svg");
}

.picto-youtube {
  background-image: url("../img/picto-youtube.svg");
}

/*////////////////////////////////////////////////////////////////*/
/* carousel */
.carousel {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .carousel {
    height: 400px;
  }
}
@media (max-width: 992px) {
  .carousel {
    height: 350px;
  }
}
@media (max-width: 768px) {
  .carousel {
    height: 320px;
  }
}
@media (max-width: 576px) {
  .carousel {
    height: 220px;
  }
}
.carousel img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel .carousel-img-top {
  opacity: 0;
  transition: opacity 600ms ease-out;
}
.carousel .carousel-img-top.show {
  opacity: 1;
}
.carousel.carousel-cropped img {
  width: 91.77777%;
}
@media (max-width: 992px) {
  .carousel.carousel-cropped img {
    width: 87%;
  }
}
@media (max-width: 768px) {
  .carousel.carousel-cropped img {
    width: 100%;
  }
}
.carousel:hover .carousel-text.on {
  opacity: 0;
  transition: opacity 300ms ease-out;
}
.carousel .carousel-text {
  position: absolute;
  display: flex;
  right: -60%;
  z-index: 2;
  width: 60%;
  height: 100%;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.2;
  opacity: 0;
  transition: left 2000ms ease-out, right 2000ms ease-out, opacity 2000ms ease-out;
}
.carousel .carousel-text.on {
  right: 0;
  opacity: 1;
}
.carousel .carousel-text .txt {
  align-self: center;
}
@media (max-width: 1200px) {
  .carousel .carousel-text {
    font-size: 1.3rem;
  }
}
@media (max-width: 992px) {
  .carousel .carousel-text {
    font-size: 1.1rem;
    width: 50%;
  }
}
@media (max-width: 768px) {
  .carousel .carousel-text {
    display: none;
  }
}
.carousel.carousel-spectacle .carousel-text {
  right: auto;
  left: 100%;
}
.carousel.carousel-spectacle .carousel-text.on {
  left: 0;
}
.carousel.carousel-spectacle img {
  width: 91%;
  right: 0;
}

/*////////////////////////////////////////////////////////////////*/
/* accordeon */
.accordeon-item {
  overflow: hidden;
  height: 35px;
  transition: height 300ms ease-out;
}
@media (max-width: 768px) {
  .accordeon-item {
    height: auto;
  }
}
.accordeon-item .accordeon-header {
  position: relative;
  display: block;
  color: #000;
  background-color: #FFF;
  padding: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  cursor: pointer;
}
.accordeon-item .accordeon-header:after {
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;
  display: block;
  content: " ";
  width: 20px;
  height: 20px;
  background: transparent url("../img/CROIX.svg") no-repeat;
  background-size: contain;
  transition: transform 500ms ease-out;
}
.accordeon-item .accordeon-header.open:after {
  transform: rotate(45deg);
}
/*////////////////////////////////////////////////////////////////*/
/* form */
.form-input-text {
  position: relative;
  border-bottom: solid 2px #FFF;
  margin-bottom: 1.2rem;
}
.form-input-text input[type=text] {
  background-color: transparent;
  width: 100%;
  outline: none;
  border: none;
}
.form-input-text label {
  left: 0;
  top: 0;
  position: absolute;
  transition: top ease-out 200ms, font-size ease-out 200ms;
}

input:focus + label, .done label {
  top: -16px;
  font-size: 0.7rem;
}

textarea {
  border: none;
  width: 100%;
}

button {
  border: none;
  background-color: #FFF;
  padding: 0.3rem 1rem;
  font-weight: 500;
}
button:focus {
  outline: none;
}

/*////////////////////////////////////////////////////////////////*/
/* mozaic */
.mozaic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5rem;
}
.mozaic .mozaic-empty {
  display: block;
  flex-basis: 370px;
  margin-bottom: 1rem;
}
@media (max-width: 1200px) {
  .mozaic .mozaic-empty {
    flex-basis: 300px;
  }
}
.mozaic .mozaic-tile {
  display: block;
  flex-basis: 370px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.mozaic .mozaic-tile.collapse {
  display: none;
}
@media (max-width: 1200px) {
  .mozaic .mozaic-tile {
    flex-basis: 300px;
  }
}
@media (max-width: 992px) {
  .mozaic .mozaic-tile {
    flex-basis: 47%;
  }
}
@media (max-width: 768px) {
  .mozaic .mozaic-tile {
    flex-basis: 100%;
  }
}
.mozaic .mozaic-tile .mozaic-content {
  position: relative;
  display: block;
  width: 100%;
  height: 330px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .mozaic .mozaic-tile .mozaic-content {
    height: 280px;
  }
}
@media (max-width: 992px) {
  .mozaic .mozaic-tile .mozaic-content {
    height: 260px;
  }
}
.mozaic .mozaic-tile .mozaic-text {
  position: absolute;
  display: block;
  text-align: left;
  padding: 1rem;
  color: #000;
  background-color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.3rem;
  width: 100%;
  height: 100%;
  bottom: -100%;
  transition: bottom ease-out 300ms;
}
@media (max-width: 1200px) {
  .mozaic .mozaic-tile .mozaic-text {
    font-size: 1.1rem;
  }
}
@media (max-width: 992px) {
  .mozaic .mozaic-tile .mozaic-text {
    display: none;
  }
}
.mozaic .mozaic-tile:hover .mozaic-text {
  bottom: 0;
}
.mozaic .mozaic-tile .mozaic-legend {
  color: #000;
  display: block;
  text-align: center;
  font-weight: bold;
}
.mozaic .mozaic-tile .mozaic-description {
  display: block;
  padding-top: 1rem;
}
.mozaic .mozaic-tile .mozaic-description h6 {
  margin-bottom: 0.75rem;
}
.mozaic .mozaic-tile .mozaic-description b {
  font-weight: 800;
}
.mozaic .mozaic-tile .mozaic-description p {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.75rem;
}
.mozaic .mozaic-categorie {
  display: block;
  flex-basis: 370px;
  margin-bottom: 1rem;
}
.mozaic .mozaic-categorie.collapse {
  display: none;
}
@media (max-width: 1200px) {
  .mozaic .mozaic-categorie {
    flex-basis: 300px;
  }
}
@media (max-width: 992px) {
  .mozaic .mozaic-categorie {
    flex-basis: 47%;
  }
}
@media (max-width: 768px) {
  .mozaic .mozaic-categorie {
    flex-basis: 100%;
  }
}
.mozaic .mozaic-categorie .mozaic-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 330px;
  background-color: #7d8995;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 4px;
  color: #FFF;
}
@media (max-width: 1200px) {
  .mozaic .mozaic-categorie .mozaic-content {
    height: 280px;
  }
}
@media (max-width: 992px) {
  .mozaic .mozaic-categorie .mozaic-content {
    height: 260px;
  }
}
.mozaic .mozaic-categorie .mozaic-content p {
  margin: 0;
}
.mozaic .mozaic-categorie .mozaic-content:after {
  position: absolute;
  display: block;
  content: " ";
  right: -19px;
  top: 0;
  bottom: 0;
  width: 20px;
  background: transparent url("../img/mozaic-categorie.png") 0 50% no-repeat;
}

/*////////////////////////////////////////////////////////////////*/
/* spectacle */
.spectacle-intro {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1;
}
.spectacle-intro h1 {
  line-height: 1;
  font-size: 1.8rem;
}
.spectacle-intro h1 small {
  font-size: 0.9rem;
}
.spectacle-intro p {
  margin: 0;
}
.spectacle-intro ul {
  padding: 0;
  margin-bottom: 0.5rem;
}
.spectacle-intro ul li {
  list-style: none;
  font-weight: 600;
}

iframe {
  margin-bottom: 1rem;
}