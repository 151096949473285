@use "sass:map";
html {
    line-height: 1.15;
    min-height: 100%;
}
*{
    font-family: $font_text ;
}
body{
    font-family: $font_text ;
    background-color: $color_body;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    min-height: 100%;
}

/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Grid                                                                              */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

.grid {
    display: grid;
    grid-template-rows: $header-height 1fr;
    grid-template-columns: $aside-width 1fr;
    height: 100%;
    min-height: 100vh;
    > aside{
        grid-row-start: 1;
        grid-row-end: 3;
    }
    > header {
        position:relative;
        grid-column-start: 2;
        grid-column-end: span 2;
    }
}


/* ///////////////////////////////////////////////////////////////////////////////////////*/
/*      Layout                                                                            */
/* ///////////////////////////////////////////////////////////////////////////////////////*/

.wrapper {
    position: relative;
    min-height: 100%;
    > .content{
        padding: 1rem .5rem 1rem 1.75rem;
    }
}

$gutter-width:7.5px;
$gutter-width-double:15px;
.row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter-width;
    margin-left: -$gutter-width;
    &.row-gutter-double {
        > div:first-child {
            padding-right: $gutter-width-double;
            padding-left: $gutter-width;
        }
        > div:last-child {
            padding-right: $gutter-width;
            padding-left: $gutter-width-double;
        }
        > div {
            padding-right: $gutter-width-double;
            padding-left: $gutter-width-double;
        }
        &.row-gutter-line {
            > div:last-child {
                border-right-width : 0;
            }
            > div {
                border-right: 1px solid rgba(0,0,0,0.1);
            }
        }
    }
    > div{
        position: relative;
        width: 100%;
        padding-right: 7.5px;
        padding-left: 7.5px;
    }

    $flex-col: 12;

    @for $i from 1 through $flex-col {
        .col-#{$i}, .col-md-#{$i}, .col-sm-#{$i}, .col-xs-#{$i} {
            flex: 0 0 (100% / ($flex-col / $i));
            max-width: (100% / ($flex-col / $i));
            @media (max-width: map.get($grid-breakpoints, "sm")) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .col-#{$i}, .col-md-#{$i}, .col-sm-#{$i}{
            @media (max-width: map.get($grid-breakpoints, "lg")) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .col-#{$i}, .col-sm-#{$i}{
            @media (max-width: map.get($grid-breakpoints, "md")) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .col-margin-left-#{$i} {
            margin-left: (100% / ($flex-col / $i));
            @media (max-width: map.get($grid-breakpoints, "md")) {
                margin-left: 0;
            }
        }
        .col-margin-right-#{$i} {
            margin-left: (100% / ($flex-col / $i));
            @media (max-width: map.get($grid-breakpoints, "md")) {
                margin-right: 0;
            }
        }

    }


}


$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);
